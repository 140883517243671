import zh from './lang/zh'
import de from './lang/de'
import jp from './lang/jp'
import es from './lang/es'
// // 引入英文语言包
import zhCN from 'vant/es/locale/lang/zh-CN';
import deDE from 'vant/lib/locale/lang/de-DE'
import jaJP from 'vant/lib/locale/lang/ja-JP'
import eses from 'vant/lib/locale/lang/de-DE'
export default {
    'hy-hy': {...zh,...zhCN},
    'de-de': {...de,...deDE},
    'jp-jp': {...jp,...jaJP},
	'es-es': {...es,...eses},
}