import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path:'/',
    redirect: '/login',
  },
  {
    path: '/index',
    name: 'index',
    component: ()=> import('@/views/index.vue'),
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'home',
        meta: {
          keepAlive: true,
          name: 'Home'
        },
        component: () => import('@/views/index/home.vue')
      },
      {
        path: '/about',
        name: 'about',
        meta: {
          keepAlive: true,
          name: 'About'
        },
        component: () => import('@/views/about/about.vue')
      },
      {
        path: '/order',
        name: 'order',
        meta: {
          keepAlive: true,
          name: 'Order'
        },
        component: () => import('@/views/order/order.vue')
      },
      {
        path: '/record',
        name: 'record',
        meta: {
          keepAlive: true,
          name: 'Record'
        },
        component: () => import('@/views/record/record.vue')
      },
      {
        path: '/user',
        name: 'user',
        meta: {
          keepAlive: true,
          name: 'User'
        },
        component: () => import('@/views/mine/user.vue')
      }
    ]
  },
  {
    path: '/invite',
    name: 'invite',
    meta: {
      keepAlive: true,
      name: 'Invite'
    },
    component: () => import('@/views/mine/cell/invite.vue')
  },
  {
    path: '/notify',
    name: 'notify',
    meta: {
      keepAlive: true,
      name: 'Notify'
    },
    component: () => import('@/views/mine/cell/notify.vue')
  },
  {
    path: '/service',
    name: 'service',
    meta: {
      keepAlive: true,
      name: 'Service'
    },
    component: () => import('@/views/mine/cell/service.vue')
  },
  {
    path: '/member',
    name: 'member',
    meta: {
      keepAlive: true,
      name: 'Member'
    },
    component: () => import('@/views/mine/cell/member.vue')
  },
  {
    path: '/userInfo',
    name: 'userInfo',
    meta: {
      keepAlive: true,
      name: 'UserInfo'
    },
    component: () => import('@/views/mine/cell/userInfo.vue')
  },
  {
    path: '/avatar',
    name: 'avatar',
    meta: {
      keepAlive: true,
      name: 'Avatar'
    },
    component: () => import('@/views/mine/cell/avatar.vue')
  },
  {
    path: '/userName',
    name: 'userName',
    meta: {
      keepAlive: true,
      name: 'UserName'
    },
    component: () => import('@/views/mine/cell/userName.vue')
  },
  {
    path: '/withdraw',
    name: 'withdraw',
    meta: {
      keepAlive: true,
      name: 'Withdraw'
    },
    component: () => import('@/views/mine/cell/withdraw.vue')
  },
  {
    path: '/addMoney',
    name: 'addMoney',
    meta: {
      keepAlive: true,
      name: 'AddMoney'
    },
    component: () => import('@/views/mine/cell/addMoney.vue')
  },
  {
    path: '/deposit',
    name: 'deposit',
    meta: {
      keepAlive: true,
      name: 'deposit'
    },
    component: () => import('@/views/mine/cell/deposit.vue')
  },
  {
    path: '/info',
    name: 'info',
    meta: {
      keepAlive: true,
      name: 'info'
    },
    component: () => import('@/views/mine/cell/info.vue')
  },
  {
    path: '/addMoneyOrder',
    name: 'addMoneyOrder',
    meta: {
      keepAlive: true,
      name: 'AddMoneyOrder'
    },
    component: () => import('@/views/mine/cell/addMoneyOrder.vue')
  },
  {
    path: '/orderDetails',
    name: 'orderDetails',
    meta: {
      keepAlive: true,
      name: 'OrderDetails'
    },
    component: () => import('@/views/mine/cell/orderDetails.vue')
  },
  {
    path: '/password',
    name: 'password',
    meta: {
      keepAlive: true,
      name: 'Password'
    },
    component: () => import('@/views/mine/cell/password.vue')
  },
  {
    path: '/passwordJy',
    name: 'passwordJy',
    meta: {
      keepAlive: true,
      name: 'PasswordJy'
    },
    component: () => import('@/views/mine/cell/passwordJy.vue')
  },
  {
    path: '/bankCard',
    name: 'bankCard',
    meta: {
      keepAlive: true,
      name: 'BankCard'
    },
    component: () => import('@/views/mine/cell/bankCard.vue')
  },
  {
    path: '/team',
    name: 'team',
    meta: {
      keepAlive: true,
      name: 'Team'
    },
    component: () => import('@/views/mine/cell/team.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      keepAlive: true,
      name: 'Login'
    },
    component: () => import('@/views/login/login.vue')
  },
  {
    path: '/register',
    name: 'register',
    meta: {
      keepAlive: true,
      name: 'Register'
    },
    component: () => import('@/views/login/register.vue')
  },
  
  {
    path: '/introduce',
    name: 'introduce',
    meta: {
      keepAlive: true,
      name: 'Introduce'
    },
    component: () => import('@/views/mine/cell/introduce.vue')
  },
  {
    path: '/platform',
    name: 'platform',
    meta: {
      keepAlive: true,
      name: 'Platform'
    },
    component: () => import('@/views/mine/cell/platform.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

const list = {
  login: 'login',
  while: ['login','register']
}
router.beforeEach((to, from, next) => {
  let token = sessionStorage.getItem('token')
  setTimeout(() => {
    document.documentElement.scrollTop = 0;
  }, 50);
  if (token) {
    return next()
  } else {
    let islogin = list.while.indexOf(to.name)
    if (islogin != -1) {
      return next()
    } else {
      return next({
        path: list.login
      })
    }
  }
})


export default router
