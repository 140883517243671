import "@/assets/common.scss";
import "vant/lib/index.css";
import { i18n, vantLocales } from "@/i18n/i18n";

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

async function setInitialLocale() {
  try {
    const response = await fetch("https://ipinfo.io/json");
    const data = await response.json();
    const countryCode = data.country;

    // 根据国家代码设置初始语言
    // if (countryCode === "CN") {
    //   localStorage.setItem("lang", "hy-hy");
    // } else
    if (countryCode === "DE") {
      i18n.locale = "de-de";
      localStorage.setItem("lang", "de-de");
    } else if (countryCode === "JP") {
      i18n.locale = "jp-jp";
      localStorage.setItem("lang", "jp-jp");
    }
  } catch (error) {
    console.error("Failed to fetch IP data:", error);
  }
}

setInitialLocale().then(() => {
  vantLocales(i18n.locale);
  createApp(App).use(store).use(router).use(i18n).mount("#app");
});
