module.exports = {
  banner1_text:
    "あなたから幸せを吸い取る人々と一緒に時間を過ごすには人生は短すぎます。 誰かがあなたを人生に望んでいるなら、彼らはあなたのために場所を空けてくれるでしょう。",
  leistungsumfang: "ライトゥングスムファン",
  leistungsumfang_text:
    "現在10万人以上の顧客にサービスを提供しており、良い評価を受けています",
  leistungsumfang_free: "無料送迎",
  home: "ホーム",
  about: "について",
  order: "完成",
  record: "完成履歴",
  user: "マイページ",
  about_text1: "品質の良い商品",
  about_text2: "さらに多くの製品",
  order_title: "本日の任務",
  zongzichan: "残高",
  jinridan: "本日の任務数",
  tiyanjin: "体験基金",
  jinrishou: "本日の収入",
  jintuandui: "本日のチームバックマージン",
  dianjigou: "スタート",
  adianjigou: "提出する",
  order_illustrate:
    "資金が誤って銀行口座に送金された場合、プラットフォームは一切の責任を負いません。.",
  order_record: "完成履歴",
  all: "全て",
  undone: "元に戻した",
  completed: "完了",
  score: "スコア",
  benutzer: "ユーザー",
  chongzhi: "補充する",
  tixian: "出金",
  details: "アカウント詳細",
  password: "パスワード管理",
  password_jy: "パスワード",
  notify: "お知らせ",
  kefu: "カウンターサービス",
  bank_card: "銀行口座",
  invite: "友達を招待",
  team: "チームレポート",
  language: "言語を選択してください",
  member: "メンバーのアップグレード",
  balance: "マイページ残高",
  quit: "ログアウト",
  invite_text1: "友達を招待して現金を獲得しましょう",
  invite_code: "招待コード",
  invite_btn: "招待リンク：クリックしてコピー",
  copy_s: "正常にコピーされました",
  copy_b: "コピーに失敗しました",
  grade: "VIP等級",
  my_money: "私の口座残高",
  open_member: "メンバーシップに参加する",
  withdraw_num: "出金回数",
  day: "空",
  withdraw_quota: "出金限度額",
  order_num: "完成数量",
  profit_scale: "バックマージン率",
  member_time: "会員資格は永久に有効です",
  confirm_pay: "支払いを確認する",
  order_sub: "完成を送信",
  user_info: "ユーザー情報",
  avatar: "アバター",
  username: "ユーザー名",
  set_up: "今すぐセットアップ",
  revise_name: "お名前の変更",
  username_hint: "ユーザー名を入力してください",
  user_account: "ユーザーアカウント",
  add_money: "補充する",
  add_money_num: "チャージ金額",
  add_money_hint1:
    "1.お支払い金額は完成金額と一致している必要があります。そうでない場合は自動的に届きません。",
  add_money_hint2:
    "2.入金・出金ができない場合,その他の問題を解決するには、上司または顧客サービスに相談してください。",
  add_money_hint3: "料金についてはカスタマーサービスにお問い合わせください",
  add_money_record: "チャージ履歴",
  withdraw_record: "出金履歴",
  withdraw_money: "出金金額",
  can_withdraw_money: "利用可能な現金の量",
  order_number: "SN",
  money: "額",
  bank: "BANK",
  transaction_flow: "トランザクションシリアル番号",
  already_paid: "私は（お金を）払いました",
  upload_pay_img: "支払いのスクリーンショットをアップロードする",
  order_details: "資金明細",
  old_password: "以前のパスワード",
  new_password: "新しいパスワード",
  repeat_password: "パスワードを再度入力してください。",
  enter: "入ってください",
  password_hint:
    "パスワードを忘れないように注意してください。パスワードを忘れた場合は、カスタマーサービスまでご連絡ください。",
  submit: "提出する",
  bankCard: "銀行口座の追加",
  bank: "口座開設銀行",
  bank_card_num: "銀行口座",
  bank_card_name: "カード所有者のお名前",
  team: "チームレポート",
  today: "今日",
  yesterday: "昨日",
  week: "今週",
  scale: "割合",
  team_people: "チームの規模",
  team_order_scale: "チームオーダーコミッション",
  open_bank_name: "アカウント名",
  phone: "電話",
  user_password: "パスワード",
  login: "ログイン",
  register: "登録する",
  password_qr: "パスワードを認証する",
  pwd_no_same: "パスワードが一致しません",
  loading: "読み込み中",
  freeze: "ロック",
  pending: "処理中",
  order_hao: "完成番号",
  order_time: "完成の受け取り時間",
  price: "単価",
  order_total_price: "完成合計",
  scale: "手数料",
  level: "会員レベル",
  level_up: "メンバーのアップアップ",
  pay_way: "チャージ方法",
  money_min: "金額が少なすぎる",
  pay_no: "チャージ方法を選択してください",
  // 新加
  team_all: "全部",
  team_yi: "レベル1",
  team_er: "レベル2",
  team_san: "レベル 3",
  close_order: "完成をキャンセルする",
  shouru: "収入",
  zhichu: "支出",
  welcome: "いらっしゃいませ",
  // 新加2
  order_kong: "完成番号を空にすることはできません",
  quer: "確認する",
  quxiao: "キャンセル",
  qianbaodi: "ウォレットアドレス",
  xingming: "お名前",
  bank_chong: "繰り返しバインドすることはできません",
  introduce: "会社概要",
  platform: "注意事項",
  tixianzhang: "引き出しマイページ",
  xuanze: "選んでください",
  xiayiye: "クリックして次のページを読み込みます",
  frequentoperation: "頻繁に操作しないでください",
};
