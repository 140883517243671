import { createStore } from 'vuex'
const lang = localStorage.getItem('lang') || ''
const fooCheck = sessionStorage.getItem('fooCheck') || 'home'
// token
const token = sessionStorage.getItem('token')

export default createStore({
  state: {
    lang,
    langList:[
      {label: '日本語', value: 'jp-jp'},
      /*{label: '简体中文', value: 'hy-hy'},
      {label: 'Deutsch', value: 'de-de'},*/
    ],
    fooCheck,
    token,
    open_recharge:false
  },
  getters: {
  },
  mutations: {
    settoken(state,value){
      state.token = value
      sessionStorage.setItem('token',value)
    },
    setlang(state,value){
      state.lang = value
      localStorage.setItem('lang',value)
    },
    setfooCheck(state,value){
      state.fooCheck = value
      sessionStorage.setItem('fooCheck',value)
    },
    setOpenRecharge(state,value){
      state.open_recharge = value
      sessionStorage.setItem('openRecharge',value)
    }
  },
  actions: {
    changetoken(context,params){  //context是一个对象，从它里面把咱们需要的commit方法解构出来
      let {commit} = context
      commit('settoken',params)
  },
    changelang(context,params){  //context是一个对象，从它里面把咱们需要的commit方法解构出来
      let {commit} = context
      commit('setlang',params)
    },
    changefooCheck(context,params){  //context是一个对象，从它里面把咱们需要的commit方法解构出来
      let {commit} = context
      commit('setfooCheck',params)
    },
    changeOpenRecharge(context,params){  //context是一个对象，从它里面把咱们需要的commit方法解构出来
      let {commit} = context
      commit('setOpenRecharge',params)
    }
  },
  modules: {

  }
})
